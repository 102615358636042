import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

/** Other components */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';

/** Icons  */
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import SaveIcon from '@material-ui/icons/Save';

import Highlight from 'react-highlight';
import './highlight.css';

import ProgressForButton from 'components/common/CircularProgressStyled';

/** Actions */
import { settingsUpdateStart } from 'actions/settingsActions';

/** Helpers */
import { fitWindow } from 'helpers/utils';
import { useTranslationWithParse } from 'helpers/translation';
import { placementsGet } from 'api/placement';

import GaAuth from './GaAuth';
import FbAuth from './FbAuth';
import VkAuth from './VkAuth';
import YaAuth from './YaAuth';

import { Settings } from 'models/settings';

const GeneralSettings = () => {
	const dispatch = useDispatch();
	const { t } = useTranslationWithParse();

	const settingsList = useSelector(state => state.settings);

	const SettingsModel = new Settings(settingsList, {
		update: payload => dispatch(settingsUpdateStart(payload)),
	});

	const [gaCounterState, setGaCounterState] = useState(null);
	const [trackIdState, setTrackIdState] = useState(null);
	const [notificationsState, setNotificationsState] = useState(null);
	const [placementAvailState, setPlacementAvailState] = useState(false);

	const [loadingState, setLoadingState] = useState({
		ga_counter: false,
		track_id: false,
		notifications: false,
	});

	const gaCounterEdit = event => {
		setGaCounterState(event.target.value);
	};

	const gaCounterSave = () => {
		SettingsModel.gaCounter = gaCounterState;
		setLoadingState({
			...loadingState,
			ga_counter: true,
		});

		SettingsModel.saveSettings();
	};

	const trackIdToggle = () => {
		const state =
			trackIdState === null ? !SettingsModel.trackId : !trackIdState;
		setTrackIdState(state);
		SettingsModel.trackId = state;
		SettingsModel.saveSettings();
	};

	const notificationsToggle = () => {
		const state =
			notificationsState === null
				? !SettingsModel.notifications
				: !notificationsState;
		setNotificationsState(state);
		SettingsModel.notifications = state;
		SettingsModel.saveSettings();
	};

	/**
	 * TODO move to helpers
	 * */
	useEffect(() => {
		if (!settingsList.fetching) {
			Object.keys(loadingState).forEach(settingKey => {
				if (typeof loadingState[settingKey] !== 'object') {
					loadingState[settingKey] = false;
				} else {
					Object.keys(loadingState[settingKey]).forEach(settingId => {
						loadingState[settingKey][settingId] = false;
					});
				}
			});

			setLoadingState(loadingState);
		}
	}, [loadingState, settingsList]);

	useEffect(() => {
		try {
			placementsGet().then(
				data => {
					setPlacementAvailState(true);
				},
				err => {
					setPlacementAvailState(false);
				},
			);
		} catch (e) {
			//console.log(e);
		}
	}, []);

	/**
	 * TODO move to helpers
	 * */
	const isFetching = useCallback(
		(key, id) => {
			console.log('isfetching');
			let isFetching = false;

			if (key in loadingState && typeof loadingState[key] === 'object') {
				isFetching = id in loadingState[key] ? loadingState[key][id] : false;
			}

			if (
				key in loadingState &&
				typeof loadingState[key] === 'object' &&
				id === false
			) {
				isFetching = Object.keys(loadingState[key]).some(
					id => !!loadingState[key][id],
				);
			}

			if (key in loadingState && typeof loadingState[key] !== 'object')
				isFetching = loadingState[key];

			return isFetching;
		},
		[settingsList, loadingState],
	);

	const b24Domain = useMemo(() => {
		return window.BX24.getDomain();
	});

	const b24MemberId = useMemo(() => {
		const b24Auth = window.BX24.getAuth();
		return b24Auth.member_id;
	}, [window.BX24]);

	/*
	useEffect(() => {
		setTimeout(() => {
			fitWindow();
		}, 1000);
	}, []);
	*/

	return (
		<div>
			<Grid container>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h5" component="h5">
							{t('Js code for placing on your website')}
						</Typography>
						<div className="blockquote-info">
							<p>ℹ️ {t('Js code description')}</p>
						</div>
						<Highlight language="javascript">
							{`
<!-- B242YA start -->
<script>
(function(w,d,u){
	var s=d.createElement('script');s.defer=false;s.async=false;s.id='b242ya-script';s.src=u+'?'+(Date.now()/60000|0);
	var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
})(window,document,'${process.env.REACT_APP_B242GA_PUBLIC_SCRIPT}');
var b242yaScript = document.querySelector('#b242ya-script');
b242yaScript.addEventListener('load', function() {
	B242YAInit({
		portal:'https://${b24Domain}/',
		pid:'${b24MemberId}',
		presets:{}
	}); 
});
</script>
<!-- B242YA end -->
`}
						</Highlight>
					</Grid>
					<Grid item xs={6}></Grid>
					<Grid item xs={6}></Grid>
				</Grid>

				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h5" component="h5">
							{t('External auth')}
						</Typography>
						<div className="blockquote-info">
							<p>ℹ️ {t('Auth description')}</p>
						</div>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={3}>
								<YaAuth />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{placementAvailState && 1===2 ? (
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<br />
							<br />
							<Typography variant="h5" component="h5">
								{t('Placement log')}
							</Typography>
							<div className="blockquote-info">
								<p> ℹ️ {t('Placement log description')}</p>
							</div>
						</Grid>
						<Grid item xs={6}>
							{t('turn off')}
							<Switch
								inputProps={{ 'aria-label': 'primary checkbox' }}
								checked={
									trackIdState !== null ? trackIdState : SettingsModel.trackId
								}
								onChange={trackIdToggle}
							/>
							{t('turn on')}
						</Grid>
						<Grid item xs={6}></Grid>
					</Grid>
				) : (
					''
				)}
				{/*
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<br />
						<br />
						<Typography variant="h5" component="h5">
							{t('GA counter by default')}
						</Typography>
						<div className="blockquote-info">
							<p> ℹ️ {t('GA counter by default description')}</p>
						</div>
					</Grid>
					<br />
					<br />
					<Grid item xs={6}>
						<TextField
							id="outlined-basic"
							style={{ width: '500px' }}
							label={t('GA counter by default')}
							variant="outlined"
							value={
								gaCounterState !== null
									? gaCounterState
									: SettingsModel.gaCounter
							}
							onChange={gaCounterEdit}
						/>
						<br />
						<br />
						<Button
							variant="contained"
							color="primary"
							onClick={gaCounterSave}
							disabled={
								gaCounterState === null ||
								gaCounterState === SettingsModel.gaCounter
							}
							style={{ width: '150px' }}
						>
							{t('Save')}
							<SaveIcon />
							{isFetching('ga_counter') ? (
								<ProgressForButton size={24} color={'default'} />
							) : null}
						</Button>
					</Grid>
					<Grid item xs={6}></Grid>
				</Grid>
			</Grid>
			<br />
			<br />


			<br />
			<br />
	
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h5" component="h5">
						Уведомление о передаче данных в GA
					</Typography>
					<div className="blockquote-info">
						<p>
							{' '}
							ℹ️ При включении опции на каждую успешную передачу цели в GA будет
							выводиться уведомление ответсвенному за Лид\Сделку
						</p>
					</div>
				</Grid>
				<Grid item xs={6}>
					выключить
					<Switch
						inputProps={{ 'aria-label': 'primary checkbox' }}
						checked={
							notificationsState !== null
								? notificationsState
								: SettingsModel.notifications
						}
						onChange={notificationsToggle}
					/>
					включить
				</Grid>
				<Grid item xs={6}></Grid>
			</Grid>

			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h5" component="h5">
						Отправлять идентификатор клиента в качестве user_id
					</Typography>
					<div className="blockquote-info">
						<p> ℹ️ При включении опции на каждую успешную передачу</p>
					</div>
				</Grid>
				<Grid item xs={6}>
					выключить
					<Switch
						inputProps={{ 'aria-label': 'primary checkbox' }}
						checked={false}
					/>
					включить
				</Grid>
				<Grid item xs={6}></Grid>
				*/}
			</Grid>
		</div>
	);
};

export default GeneralSettings;
